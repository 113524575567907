import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { Container, ThemeUIStyleObject } from 'theme-ui';
import useProductVariants from '~/hooks/components/use-product-variants';
import filterDuplicates from '~/utils/filter-duplicates';
import ProductAddToCart from '../AddToCart';

import PDPDetails from '~/components/Generic/PDPWrapper/Details';
import PDPContent from '~/components/Generic/PDPWrapper/Content';
import useProductAvailability from '~/hooks/components/use-product-availability';

type Props = {
  product: Queries.ProductPageFragmentFragment;
  notifications: { pdpNotifications: Queries.NotificationFragmentFragment[] };
  collectionName: string;
  forwardSx?: ThemeUIStyleObject;
  setVariant: Dispatch<SetStateAction<Queries.VariantDetailsFragmentFragment | null>>;
};

const ProductDetails = ({ product, notifications, collectionName, setVariant, forwardSx }: Props) => {
  const OOSNotify = useRef<Queries.NotificationFragmentFragment | null>(null);
  const comingSoonNotify = useRef<Queries.NotificationFragmentFragment | null>(null);
  const discontinuedNotify = useRef<Queries.NotificationFragmentFragment | null>(null);
  const freeShipping = useRef<Queries.NotificationFragmentFragment | null>(null);
  const { productWithStock, isFetching: isFetchingAvailability } = useProductAvailability(product);
  const [productAvailable, setProductAvailable] = useState(true);

  const { microloadHighlight } = product;

  useEffect(() => {
    const setSections = () => {
      notifications?.pdpNotifications.forEach((s) => {
        s?.slug === 'notification-oos-pdp' && s && (OOSNotify.current = s);
        s?.slug === 'notification-comingsoon-pdp' && s && (comingSoonNotify.current = s);
        s?.slug === 'notification-discontinued-pdp' && s && (discontinuedNotify.current = s);
        s?.slug === 'pdp-free-shipping' && s && (freeShipping.current = s);
      });
    };
    setSections();
  }, [notifications]);

  const { currentVariant, selectVariant } = useProductVariants(product, 'details', collectionName, product?.slug ?? '');
  const { price, regularPrice } = currentVariant ?? {};

  // Option values available across variants
  const availableOptionValues = filterDuplicates(
    product?.variants?.reduce((acc: (Queries.OptionValuesFragmentFragment | null)[], variant) => {
      if (variant?.optionValues && acc) return [...acc, ...variant?.optionValues];
      return acc;
    }, []),
    'slug'
  );

  useEffect(() => {
    if (currentVariant) setVariant(currentVariant);
  }, [currentVariant]);

  return (
    <Container
      as="section"
      variant="fullwidth"
      sx={{
        mx: 0,
        display: 'flex',
        flexDirection: 'column',
        gap: 'var(--vertical-spacing)',
        ...forwardSx,
      }}
    >
      <PDPDetails
        price={price ?? 0}
        regularPrice={regularPrice ?? 0}
        title={product.longName}
        subtitle={microloadHighlight}
        slug={product.slug}
        showReviews={product.showYotpoStars}
      />

      <ProductAddToCart
        comingSoonNotify={comingSoonNotify}
        OOSNotify={OOSNotify}
        freeShipping={freeShipping}
        productAvailable={productAvailable}
        setProductAvailable={setProductAvailable}
        productName={product?.name?.toLowerCase()}
        optionValues={availableOptionValues}
        variants={productWithStock?.variants}
        activeVariant={currentVariant?.id}
        product={productWithStock}
        onVariantChange={selectVariant}
        isFetchingAvailability={isFetchingAvailability}
        forwardSx={{ paddingTop: [null, 0] }}
      />

      <PDPContent item={product} />
    </Container>
  );
};

export default ProductDetails;
