const formatInches = (inches) => {
  if (!inches) return '';

  const whole = Math.floor(inches / 12);
  const reminder = inches % 12;

  return `${whole}ft${reminder ? ` ${reminder}in` : ''}`;
};

export default formatInches;
